/* eslint-disable no-nested-ternary */
import { Icon, Icons, Illustrations, Link, keyboardOutline } from '@loveholidays/design-system';
import React, { Fragment } from 'react';
import { SxStyleProp } from 'theme-ui';

import { MegaMenuSection } from './MegaMenuSection';
import { MegaMenuItem } from '@AuroraTypes';
import { HTMLElementProps } from '@ComponentProps';
import { Illustration } from '@DesignSystemComponents/Illustration';
import { Accordion } from '@UX/Accordion/Accordion';
import { pageContainerStyles, pageContainerWidthOnDesktop } from '@UX/Page/LegacyPageContainer';

interface MegaMenuContentProps extends HTMLElementProps {
  items: MegaMenuItem[];
}

const itemStyles: SxStyleProp = {
  display: 'block',
  width: '100%',
  maxWidth: [null, null, pageContainerWidthOnDesktop],
  paddingY: '3xs',
  paddingX: ['3xs', 'xs', '3xs'],
  marginX: 'auto',
  borderStyle: 'solid',
  borderColor: 'megaMenuDark',
  borderWidth: 0,
  borderBottomWidth: 1,
  fontSize: 'm',
  lineHeight: 'normal',
  textAlign: 'left',
  '&, &:visited': {
    color: 'white',
    textDecoration: 'none',
  },
  ':hover': {
    color: 'white',
    textDecoration: 'underline',
  },
};

export const MegaMenuContent: React.FC<MegaMenuContentProps> = React.memo(({ items, ...props }) => (
  <ul
    aria-label="Mega menu"
    {...props}
  >
    <Accordion>
      {items.map(({ label, url, iconName, illustrationName, children, subChildren, asAnchor }) => {
        const id = label.replace(/\s/g, '-');
        const labelId = `${id}-control`;

        return (
          <li key={label}>
            {children?.length ? (
              <Fragment>
                <Accordion.Toggle
                  id={labelId}
                  controls={id}
                  sx={{
                    ...itemStyles,
                    ...keyboardOutline,
                  }}
                  showChevrons
                >
                  {label}
                </Accordion.Toggle>

                <Accordion.Collapse
                  id={id}
                  aria-labelledby={labelId}
                  sx={{
                    backgroundColor: 'white',
                    paddingY: 'xs',
                  }}
                >
                  <MegaMenuSection
                    items={children}
                    sx={{
                      ...pageContainerStyles(true),
                      paddingX: [null, null, '3xs'],
                    }}
                  />
                  {!!subChildren?.length && (
                    <MegaMenuSection
                      items={subChildren}
                      level={4}
                      sx={{
                        ...pageContainerStyles(true),
                        paddingX: [null, null, '3xs'],
                      }}
                    />
                  )}
                </Accordion.Collapse>
              </Fragment>
            ) : (
              <Link
                href={url!}
                sx={itemStyles}
                asAnchor={!!asAnchor}
              >
                {iconName && (
                  <Icon
                    name={iconName as any as Icons}
                    size="20"
                    sx={{
                      marginRight: '3xs',
                      verticalAlign: 'middle',
                    }}
                  />
                )}
                {illustrationName && (
                  <Illustration
                    name={illustrationName as any as Illustrations}
                    size="20"
                    sx={{
                      marginRight: '3xs',
                      verticalAlign: 'middle',
                    }}
                  />
                )}
                {label}
              </Link>
            )}
          </li>
        );
      })}
    </Accordion>
  </ul>
));
